var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineStore } from "pinia";
import { toPromise } from "src/shared/api/promise";
import FileAssetEndpoints from "src/shared/api/file/FileAssetEndpoints";
import _ from "underscore";
import { reactive } from "vue";
import { FileAsset } from "./model/FileAsset";
import Strings from "src/shared/Strings";
const convertFileAssetFetchParameters = (parameters) => {
    return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (parameters.statuses !== undefined && {
        statuses: parameters.statuses.join(","),
    })), (parameters.name !== undefined && {
        display_name: parameters.name,
    })), (parameters.user_id !== undefined && {
        description: parameters.user_id,
    })), (parameters.tags !== undefined && {
        tags: parameters.tags.join(","),
    })), (parameters.offset !== undefined && {
        offset: String(parameters.offset),
    })), (parameters.limit !== undefined && {
        limit: String(parameters.limit),
    }));
};
const convetToFileAssetCreateRequest = (data) => {
    var _a;
    return Object.assign(Object.assign({}, (data.metadata.name !== undefined && {
        name: Strings.encodeSnakeCase(data.metadata.name || ((_a = data.file) === null || _a === void 0 ? void 0 : _a.name)),
    })), (data.metadata.tags && {
        tags: data.metadata.tags,
    }));
};
export const useFileAssetStore = defineStore("fileAssetStore", () => {
    const state = reactive({
        fileAssets: {},
    });
    const setFileAssets = (fileAssetResponse) => {
        _.each(fileAssetResponse, (response) => {
            const fileAsset = new FileAsset(response);
            state.fileAssets[fileAsset.id] = fileAsset;
        });
    };
    const fetchFileAssets = (parameters = {}) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield toPromise(FileAssetEndpoints.list(convertFileAssetFetchParameters(parameters)));
        setFileAssets(response);
    });
    const getFileAssets = () => _.values(state.fileAssets);
    const getFileAsset = (fileAssetId) => {
        return state.fileAssets[fileAssetId];
    };
    const downloadFileAsset = (fileAssetId) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield toPromise(FileAssetEndpoints.download(fileAssetId));
    });
    const createFileAsset = (data) => __awaiter(void 0, void 0, void 0, function* () {
        const formData = new FormData();
        if (data) {
            formData.append("metadata", JSON.stringify(convetToFileAssetCreateRequest(data)));
        }
        if (data.file) {
            formData.append("file", data.file, data.file.name);
        }
        const fileResponse = yield toPromise(FileAssetEndpoints.create(formData));
        yield fetchFileAssets();
        return fileResponse;
    });
    return {
        state,
        fetchFileAssets,
        getFileAssets,
        getFileAsset,
        downloadFileAsset,
        createFileAsset,
    };
});
