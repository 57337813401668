var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineStore } from "pinia";
import CampaignComponentEndpoints from "src/shared/api/campaign/component/CampaignComponentEndpoints";
import ComponentEndpoints from "src/shared/api/campaign/component/ComponentEndpoints";
import { toPromise } from "src/shared/api/promise";
import _ from "underscore";
import { reactive } from "vue";
import BuiltComponent from "./model/BuiltComponent";
import { useComponentGraphStore } from "src/shared/store/component-graph";
import { campaignComponentChangeEvent, campaignComponentDeletedEvent, campaignComponentListChangeEvent, } from "../event-bus/campaign-component";
import { createCampaignComponentIndex } from "../component/createCampaignComponentIndex";
import { campaignArchiveEvent } from "../event-bus/campaign";
const convertCampaignComponentResponseToComponentResponse = (builtCampaignComponentResponse, campaignId) => {
    return {
        campaign_id: campaignId,
        id: builtCampaignComponentResponse.id,
        name: builtCampaignComponentResponse.name,
        description: builtCampaignComponentResponse.description,
        tags: builtCampaignComponentResponse.tags,
        variables: builtCampaignComponentResponse.variables,
        component_ids: builtCampaignComponentResponse.component_ids,
        created_date: builtCampaignComponentResponse.created_date,
        updated_date: builtCampaignComponentResponse.updated_date,
        owner: "CLIENT",
        campaign_state: "BUILT",
        assets: [],
        component_version: builtCampaignComponentResponse.component_version,
        absolute_names: builtCampaignComponentResponse.absolute_names,
    };
};
export const useBuiltComponentStore = defineStore("builtComponentStore", () => {
    const componentGraphStore = useComponentGraphStore();
    const state = reactive({
        builtComponents: {},
    });
    const campaignComponentIndex = createCampaignComponentIndex();
    const setComponents = (builtComponentResponses) => {
        _.each(builtComponentResponses, (response) => {
            const component = new BuiltComponent(response);
            state.builtComponents[component.id] = component;
            componentGraphStore.addComponent(component);
            campaignComponentIndex
                .get(component.campaign_id)
                .addComponentId(component.id);
        });
    };
    const fetchBuiltComponents = (data) => __awaiter(void 0, void 0, void 0, function* () {
        setComponents(yield toPromise(ComponentEndpoints.listBuilt(data)));
    });
    const fetchCampaignBuiltComponents = (campaignId) => __awaiter(void 0, void 0, void 0, function* () {
        const builtCampaignComponentResponses = yield toPromise(CampaignComponentEndpoints.listBuilt(campaignId));
        const builtComponentResponses = _.map(builtCampaignComponentResponses, (builtCampaignComponentResponse) => {
            return convertCampaignComponentResponseToComponentResponse(builtCampaignComponentResponse, campaignId);
        });
        setComponents(builtComponentResponses);
    });
    const fetchBuiltComponent = (componentId) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield toPromise(ComponentEndpoints.getBuilt(componentId));
        setComponents([response]);
    });
    const getBuiltComponent = (id) => {
        return state.builtComponents[id];
    };
    const getBuiltComponents = () => {
        return _.values(state.builtComponents);
    };
    const getCampaignBuiltComponents = (campaignId) => {
        const index = campaignComponentIndex.get(campaignId);
        return _.map(index.getComponentIds(), (componentId) => {
            return state.builtComponents[componentId];
        });
    };
    campaignComponentChangeEvent.on((event) => {
        fetchBuiltComponent(event.componentId);
    });
    campaignComponentListChangeEvent.on((event) => {
        fetchCampaignBuiltComponents(event.campaignId);
    });
    campaignComponentDeletedEvent.on((payload) => {
        delete state.builtComponents[payload.componentId];
    });
    campaignArchiveEvent.on((payload) => {
        const components = getBuiltComponents();
        const deletedComponents = _.filter(components, (component) => {
            return component.campaign_id === payload.campaignId;
        });
        _.each(deletedComponents, (component) => {
            delete state.builtComponents[component.id];
        });
    });
    return {
        state,
        fetchBuiltComponents,
        fetchCampaignBuiltComponents,
        fetchBuiltComponent,
        getBuiltComponents,
        getCampaignBuiltComponents,
        getBuiltComponent,
    };
});
