var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineStore } from "pinia";
import { toPromise } from "src/shared/api/promise";
import AudienceOperationEndpoints from "src/shared/api/audience/operation/AudienceOperationEndpoints";
import { reactive } from "vue";
import { createAudienceOperation } from "./model/factory";
import { createDetailedAudienceOperation } from "./model/detail/factory";
import { createAudienceOperationState } from "./model/state/factory";
import { each } from "lodash";
import { audienceOperationChangeEvent } from "./event-bus";
import { audienceArchiveEvent } from "../event-bus";
import { createAudienceOperationDebug } from "./model/debug/factory";
const convertAudienceFetchParameters = (parameters) => {
    return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (parameters.type !== undefined && {
        type: String(parameters.type),
    })), (parameters.offset !== undefined && {
        offset: String(parameters.offset),
    })), (parameters.limit !== undefined && {
        limit: String(parameters.limit),
    })), (parameters.states !== undefined && {
        states: parameters.states.join(","),
    })), (parameters.tags !== undefined && {
        tags: parameters.tags.join(","),
    }));
};
const convertToCreateRequest = (data) => {
    return Object.assign({ type: data.type, data_source: data.data_source }, (data.tags !== undefined &&
        data.tags.length > 0 && {
        tags: data.tags,
    }));
};
export const useAudienceOperationsStore = defineStore("audienceOperationStore", () => {
    const state = reactive({
        operations: {},
        operationDetails: {},
        operationStates: {},
        operationDebug: {},
    });
    const refreshTimeouts = reactive({});
    const scheduleRefresh = (audienceId, operationId, operationState) => {
        if (!operationState.isInProgress() &&
            refreshTimeouts[operationId]) {
            clearTimeout(refreshTimeouts[operationId]);
            delete refreshTimeouts[operationId];
            return;
        }
        refreshTimeouts[operationId] = setTimeout(() => __awaiter(void 0, void 0, void 0, function* () {
            yield fetchOperationState(audienceId, operationId);
        }), 10000);
    };
    const setOperations = (audienceId, operationsResponse) => {
        state.operations[audienceId] = [];
        each(operationsResponse, (response) => {
            try {
                const operation = createAudienceOperation(response);
                state.operations[audienceId].push(operation);
            }
            catch (error) {
                console.error(error);
            }
        });
    };
    const setOperationDetail = (operationId, operationDetailResponse) => {
        try {
            const operationDetail = createDetailedAudienceOperation(operationDetailResponse);
            state.operationDetails[operationId] = operationDetail;
        }
        catch (error) {
            console.error(error);
        }
    };
    const setOperationState = (audienceId, operationId, stateResponse) => {
        try {
            const operationState = createAudienceOperationState(stateResponse);
            if (!state.operationStates[operationId]) {
                state.operationStates[operationId] = operationState;
            }
            else {
                Object.assign(state.operationStates[operationId], operationState);
            }
            scheduleRefresh(audienceId, operationId, operationState);
        }
        catch (error) {
            console.error(error);
        }
    };
    const setOperationDebug = (operationId, debugResponse) => {
        try {
            const operationDebug = createAudienceOperationDebug(debugResponse);
            state.operationDebug[operationId] = operationDebug;
        }
        catch (error) {
            console.error(error);
        }
    };
    const fetchOperations = (audienceId, parameters = {}) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield toPromise(AudienceOperationEndpoints.list(audienceId, convertAudienceFetchParameters(parameters)));
        setOperations(audienceId, response);
    });
    const fetchOperationDetail = (audienceId, operationId) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield toPromise(AudienceOperationEndpoints.getDetails(audienceId, operationId));
        setOperationDetail(operationId, response);
    });
    const fetchOperationState = (audienceId, operationId) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield toPromise(AudienceOperationEndpoints.getState(audienceId, operationId));
        setOperationState(audienceId, operationId, response);
    });
    const fetchOperationDebug = (audienceId, operationId) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield toPromise(AudienceOperationEndpoints.getDebug(audienceId, operationId));
        setOperationDebug(operationId, response);
    });
    const listOperations = (audienceId) => {
        return state.operations[audienceId];
    };
    const getOperation = (audienceId, operationId) => {
        const operations = listOperations(audienceId);
        return operations.find((operation) => operation.id === operationId);
    };
    const getOperationDetail = (operationId) => {
        return state.operationDetails[operationId];
    };
    const getOperationState = (operationId) => {
        return state.operationStates[operationId];
    };
    const getOperationDebug = (operationId) => {
        return state.operationDebug[operationId];
    };
    const createOperation = (audienceId, data) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield toPromise(AudienceOperationEndpoints.create(audienceId, convertToCreateRequest(data)));
        yield fetchOperations(audienceId);
        audienceOperationChangeEvent.emit({
            changedAudienceId: audienceId,
            changedOperationId: response.id,
        });
        return response;
    });
    const cancelOperation = (audienceId, operationId) => __awaiter(void 0, void 0, void 0, function* () {
        yield toPromise(AudienceOperationEndpoints.cancel(audienceId, operationId));
        yield fetchOperations(audienceId);
        audienceOperationChangeEvent.emit({
            changedAudienceId: audienceId,
            changedOperationId: operationId,
        });
    });
    audienceArchiveEvent.on(({ audienceId }) => {
        delete state.operations[audienceId];
    });
    return {
        state,
        fetchOperations,
        listOperations,
        getOperation,
        createOperation,
        fetchOperationDetail,
        getOperationDetail,
        fetchOperationState,
        getOperationState,
        fetchOperationDebug,
        getOperationDebug,
        cancelOperation,
    };
});
