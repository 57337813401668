import { has } from "lodash";
import { CampaignComponentVariableCommonImpl } from "../../campaign-component/variable/model/CampaignComponentVariableCommonImpl";
const DEFAULT_VARIANT = "default";
export const resolveComponentVariableValue = (targetVariant, defaultVariant, values) => {
    if (targetVariant && has(values, targetVariant)) {
        return {
            variant: targetVariant,
            value: values[targetVariant],
        };
    }
    if (defaultVariant && has(values, defaultVariant)) {
        return {
            variant: defaultVariant,
            value: values[defaultVariant],
        };
    }
    return {
        variant: DEFAULT_VARIANT,
        value: values[DEFAULT_VARIANT],
    };
};
class BuiltCampaignComponentVariable {
    constructor(builtVariableResponse, context) {
        this.campaignComponentVariableCommonImpl =
            new CampaignComponentVariableCommonImpl(builtVariableResponse);
        this.name = builtVariableResponse.name;
        this.display_name = builtVariableResponse.display_name;
        this.description = builtVariableResponse.description;
        this.source = builtVariableResponse.source;
        this.type = builtVariableResponse.type;
        this.values = builtVariableResponse.values;
        this.tags = builtVariableResponse.tags;
        this.source_component_id = builtVariableResponse.source_component_id;
        this.context = context;
    }
    getDisplayName() {
        return this.campaignComponentVariableCommonImpl.getDisplayName();
    }
    isTranslatable() {
        return this.campaignComponentVariableCommonImpl.isTranslatable();
    }
    isHidden() {
        return this.campaignComponentVariableCommonImpl.isHidden();
    }
    isUIDisplayOnly() {
        return this.campaignComponentVariableCommonImpl.isUIDisplayOnly();
    }
    getImportance() {
        return this.campaignComponentVariableCommonImpl.getImportance();
    }
    getPrimaryCategory() {
        return this.campaignComponentVariableCommonImpl.getPrimaryCategory();
    }
    getSecondaryCategory() {
        return this.campaignComponentVariableCommonImpl.getSecondaryCategory();
    }
    hasVariant(variant) {
        return this.campaignComponentVariableCommonImpl.hasVariant(variant);
    }
    getValue(variant) {
        return this.campaignComponentVariableCommonImpl.getValue(variant);
    }
    isInheriting() {
        return this.source_component_id !== this.context.componentId;
    }
    isAdvanced() {
        return this.campaignComponentVariableCommonImpl.isAdvanced();
    }
}
export default BuiltCampaignComponentVariable;
