var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { notify } from "src/shared/v-components/ui/notification/notification-service";
import { ref, reactive } from "vue";
import { useAudienceStore } from "..";
import { useFileAssetStore } from "../../file";
import { FileAssetMutable } from "../../file/model/FileAssetMutable";
import { useAudienceOperationsStore } from "../operation";
import { AudienceCreateMutable, ModificationAudienceOperationCreateMutable, } from "../types";
const fileAssetMutable = ref(new FileAssetMutable());
const audienceMutable = ref(new AudienceCreateMutable());
const operationMutable = reactive(new ModificationAudienceOperationCreateMutable("FILE_ASSET"));
const isSaving = ref(false);
const isSuccess = ref(false);
const isRequestFinished = ref(false);
const file = ref(undefined);
const audience = ref(undefined);
const isAudienceCreation = ref(false);
export const useCreateAudience = () => {
    const fileAssetStore = useFileAssetStore();
    const audienceStore = useAudienceStore();
    const operationStore = useAudienceOperationsStore();
    const createEmptyAudience = (event) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (!((_a = audienceMutable.value.name) === null || _a === void 0 ? void 0 : _a.trim())) {
            return;
        }
        isSaving.value = true;
        try {
            const response = yield audienceStore.createAudience(audienceMutable.value);
            audience.value = audienceStore.getAudience(response.id);
            if (event) {
                notify({
                    type: "success",
                    message: `Your empty audience ${audienceMutable.value.name} has been created!`,
                });
                audienceMutable.value.name = "";
                isSaving.value = false;
            }
        }
        catch (error) {
            notify({
                type: "error",
                message: "Something went wrong with creating your audience",
            });
            isSaving.value = false;
            return;
        }
        finally {
            isSaving.value = false;
        }
    });
    const createFromFile = () => __awaiter(void 0, void 0, void 0, function* () {
        yield createEmptyAudience();
        if (!audience.value) {
            return;
        }
        if (fileAssetMutable.value.file) {
            const metadata = {
                name: `${audienceMutable.value.name}-${Date.now()}`,
                tags: operationMutable.tags,
            };
            fileAssetMutable.value.metadata = metadata;
            try {
                const fileResponse = yield fileAssetStore.createFileAsset(fileAssetMutable.value);
                file.value = fileAssetStore.getFileAsset(fileResponse.id);
            }
            catch (error) {
                notify({
                    type: "error",
                    message: "Something went wrong with uploading your file",
                });
                return;
            }
        }
        try {
            if (file.value &&
                operationMutable.isFileAssetDataSource() &&
                audience.value) {
                operationMutable.data_source.file_asset_id = file.value.id;
                yield operationStore.createOperation(audience.value.id, operationMutable);
                notify({
                    type: "success",
                    message: `Your audience ${audienceMutable.value.name} has been created!`,
                });
                isSuccess.value = true;
            }
            else {
                return;
            }
        }
        catch (error) {
            notify({
                type: "error",
                message: "Something went wrong",
            });
        }
        finally {
            isRequestFinished.value = true;
            isSaving.value = false;
        }
    });
    return {
        audienceMutable,
        fileAssetMutable,
        operationMutable,
        audience,
        isSaving,
        isSuccess,
        isAudienceCreation,
        isRequestFinished,
        createEmptyAudience,
        createFromFile,
    };
};
