import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Session from "Session";

const PREFIX = "_feature_flag_";
const ON = "ON";
const OFF = "OFF";
const NOT_CONFIGURED = "NOT_CONFIGURED";

const PropertyModel = Backbone.Model.extend({
    idAttribute: "name",
    sync(method, model, options) {
        if (method === "update") {
            options.attrs = _.pick(this.attributes, "value");
        }
        return Backbone.sync.call(this, method, model, options);
    },

    parse(response) {
        if (response.name) {
            this._id = response.name;
        }
        return response;
    },

    isNew() {
        if (this._id) {
            return false;
        }
        return true;
    },
});

const ClientPropertyCollection = Backbone.Collection.extend({
    url: "api:///v2/properties",
    model: PropertyModel,
});

const DefaultPropertyCollection = Backbone.Collection.extend({
    url: "api:///v2/default/properties",
    model: PropertyModel,
});

const ClientFlagCollection = ClientPropertyCollection.extend({
    initialize(models, parameters) {
        this.name = parameters.name;
    },
    parse(response) {
        const self = this;
        return _.filter(response, (item) => item.name === self.name);
    },
});

const GlobalFlagCollection = DefaultPropertyCollection.extend({
    initialize(models, parameters) {
        this.name = parameters.name;
    },
    parse(response) {
        const self = this;
        return _.filter(response, (item) => item.name === self.name);
    },
});

const BROWSER_FLAGS = "BROWSER_FLAGS";

const BrowserPropertyModel = Backbone.Model.extend({
    save() {
        const deferred = $.Deferred();
        const raw = window.localStorage.getItem(BROWSER_FLAGS);
        const parsed = raw ? JSON.parse(raw) : [];
        const name = this.get("name");
        const existing = _.findWhere(parsed, {
            name,
        });
        const model = this;
        if (existing) {
            _.extend(existing, model.toJSON());
            window.localStorage.setItem(BROWSER_FLAGS, JSON.stringify(parsed));
            model.trigger("sync");
            deferred.resolve();
        } else {
            setTimeout(() => {
                parsed.push(model.toJSON());
                window.localStorage.setItem(
                    BROWSER_FLAGS,
                    JSON.stringify(parsed)
                );
                model.trigger("sync");
                deferred.resolve();
            }, 0);
        }
        return deferred;
    },
    destroy() {
        const deferred = $.Deferred();
        const raw = window.localStorage.getItem(BROWSER_FLAGS);
        const parsed = raw ? JSON.parse(raw) : [];
        const name = this.get("name");
        const existing = _.findWhere(parsed, {
            name,
        });
        const model = this;
        if (existing) {
            setTimeout(() => {
                const without = _.filter(parsed, (item) => item !== existing);
                window.localStorage.setItem(
                    BROWSER_FLAGS,
                    JSON.stringify(without)
                );
                model.trigger("destroy", model);
                deferred.resolve();
            }, 0);
        }
        return deferred;
    },
});

export const BrowserFlagCollection = Backbone.Collection.extend({
    initialize(models, parameters) {
        this.name = parameters.name;
    },
    model: BrowserPropertyModel,
    parse(response) {
        const self = this;
        return _.filter(response, (item) => item.name === self.name);
    },
    _fetchSync() {
        const raw = window.localStorage.getItem(BROWSER_FLAGS);
        const response = raw ? JSON.parse(raw) : [];
        const collection = this;
        collection.set(response, {
            parse: true,
        });
        collection.trigger("sync");
    },
    fetch() {
        const deferred = $.Deferred();
        const raw = window.localStorage.getItem(BROWSER_FLAGS);
        const response = raw ? JSON.parse(raw) : [];
        const collection = this;
        setTimeout(() => {
            collection.set(response, {
                parse: true,
            });
            collection.trigger("sync");
            deferred.resolve();
        }, 0);
        return deferred;
    },

    create(attributes) {
        const collection = this;
        const model = new BrowserPropertyModel(attributes);
        $.when(model.save()).done(() => {
            collection.add(model);
            collection.trigger("sync");
        });
    },
});

function relay(event, source, target) {
    source.on(event, () => {
        target.trigger(event);
    });
}

const FeatureFlagModel = Backbone.Model.extend({
    initialize() {
        this.clientFlags = new ClientFlagCollection([], {
            name: this.get("name"),
        });
        this.globalFlags = new GlobalFlagCollection([], {
            name: this.get("name"),
        });
        this.browserFlags = new BrowserFlagCollection([], {
            name: this.get("name"),
        });

        relay("sync", this.globalFlags, this);
        relay("sync", this.clientFlags, this);
        relay("sync", this.browserFlags, this);

        relay("destroy", this.globalFlags, this);
        relay("destroy", this.clientFlags, this);
        relay("destroy", this.browserFlags, this);
    },

    toJSON() {
        return {
            name: this.get("name"),
            display_name: this.get("displayName"),
            description: this.get("description"),
            state_global: this.getState("GLOBAL"),
            state_client: this.getState("CLIENT"),
            state_browser: this.getState("BROWSER"),
        };
    },

    bootstrap() {
        const deferred = $.Deferred();
        $.when(
            this.clientFlags.fetch(),
            this.globalFlags.fetch(),
            this.browserFlags.fetch()
        ).done(deferred.resolve);
        return deferred;
    },

    getCollectionByLevel(level) {
        switch (level) {
            case "GLOBAL":
                return this.globalFlags;
            case "CLIENT":
                return this.clientFlags;
            case "BROWSER":
                return this.browserFlags;
            default:
                throw new Error(`Level not supported: ${level}`);
        }
    },

    getState(level) {
        const flags = this.getCollectionByLevel(level);
        if (flags.isEmpty()) {
            return NOT_CONFIGURED;
        }
        const flag = flags.first();
        const value = flag.get("value");
        if (value === "ON") {
            return ON;
        }
        return OFF;
    },

    turnOn(level) {
        const collection = this.getCollectionByLevel(level);
        if (collection.isEmpty()) {
            collection.create({
                name: this.get("name"),
                value: ON,
            });
        } else {
            const model = collection.first();
            model.set("value", ON);
            model.save();
        }
    },

    turnOff(level) {
        const collection = this.getCollectionByLevel(level);
        if (collection.isEmpty()) {
            collection.create({
                name: this.get("name"),
                value: OFF,
            });
        } else {
            const model = collection.first();
            model.set("value", OFF);
            model.save();
        }
    },

    removeConfiguration(level) {
        const collection = this.getCollectionByLevel(level);
        const model = collection.first();
        if (model) {
            model.destroy();
        }
    },

    isOn() {
        const name = this.get("name");
        let value;

        value = Session.getInstance().getProperties()[name];

        this.browserFlags._fetchSync();
        const flag = this.browserFlags.first();
        if (flag) {
            value = flag.get("value");
        }

        return value === "ON";
    },
});

const FeatureFlagCollection = Backbone.Collection.extend({
    model: FeatureFlagModel,
    load() {
        const deferred = $.Deferred();
        const fetches = this.map((flag) => flag.bootstrap());
        $.when.apply(null, fetches).done(() => {
            deferred.resolve();
        });
        return deferred;
    },
});

const FLAGS = {
    NOMINATION: "NOMINATION",
    SWEEPSTAKES: "SWEEPSTAKES",
    CLIENT_LEVEL_DASHBOARD_UI: "CLIENT_LEVEL_DASHBOARD_UI",
    DASHBOARD_UI: "DASHBOARD_UI",
    ALLOW_EVALUATABLE_EDITING: "ALLOW_EVALUATABLE_EDITING",
    REWARD_SLOTS_DETAILS: "REWARD_SLOTS_DETAILS",
    HIDE_COUPON_CODE: "HIDE_COUPON_CODE",
    HIDE_CART_VALUE: "HIDE_CART_VALUE",
    HIDE_PII: "HIDE_PII",
    GENERIC_QUALITY_RULE: "GENERIC_QUALITY_RULE",
    FIXUPS_PAGE: "FIXUPS_PAGE",
    CHAT_BOT: "CHAT_BOT",
    PAYPAL_REWARD_SUPPLIER: "PAYPAL_REWARD_SUPPLIER",
    SHOW_ALL_VARIABLES: "SHOW_ALL_VARIABLES",
    ADVOCATE_MARKETING_PACKAGE: "ADVOCATE_MARKETING_PACKAGE",
    DROP_A_HINT_PROGRAM: "DROP_A_HINT_PROGRAM",
    INFLUENCER_PROGRAM: "INFLUENCER_PROGRAM",
    FRIENDS_AND_FAMILY: "FRIENDS_AND_FAMILY",
    WELCOME_OFFER: "WELCOME_OFFER",
    OFFER: "OFFER",
    CAMPAIGN_COMPONENTS: "CAMPAIGN_COMPONENTS",
    SELF_SERVICE_PAGE: "SELF_SERVICE_PAGE",
    AI_SUPPORT: "AI_SUPPORT",
    AUDIENCE_TAB: "AUDIENCE_TAB",
    CAMPAIGN_EDIT_V4: "CAMPAIGN_EDIT_V4",
    ENFORCE_SHOPIFY_RESTRICTIONS: "ENFORCE_SHOPIFY_RESTRICTIONS",
    BETA_COMPONENT_LIBRARY: "BETA_COMPONENT_LIBRARY",
};

const flags = new FeatureFlagCollection([
    {
        id: FLAGS.AUDIENCE_TAB,
        name: PREFIX + FLAGS.AUDIENCE_TAB,
        displayName: "Audience Tab UI",
        description: "Enable Audience Tab UI in the Campaign Editor",
    },
    {
        id: FLAGS.AI_SUPPORT,
        name: PREFIX + FLAGS.AI_SUPPORT,
        displayName: "AI Support",
        description: "Report descriptions and prehandlers powered by AI",
    },
    {
        id: FLAGS.CAMPAIGN_EDIT_V4,
        name: PREFIX + FLAGS.CAMPAIGN_EDIT_V4,
        displayName: "Campaign Edit V4",
        description: "Enable Campaign Edit V4",
    },
    {
        id: FLAGS.ALLOW_EVALUATABLE_EDITING,
        name: PREFIX + FLAGS.ALLOW_EVALUATABLE_EDITING,
        displayName: "Evaluatable Editing",
        description:
            "Evaluatable editing for component fields across My.Extole",
    },
    {
        id: FLAGS.CAMPAIGN_COMPONENTS,
        name: PREFIX + FLAGS.CAMPAIGN_COMPONENTS,
        displayName: "Manage Page Component Menu Item UI",
        description:
            "View menu items for a flow step or Business Event to create new promotions or emails (promotion and email components)",
    },
    {
        id: FLAGS.SELF_SERVICE_PAGE,
        name: PREFIX + FLAGS.SELF_SERVICE_PAGE,
        displayName: "Self Service Page Menu Item",
        description:
            "Access custom self service pages from a left navigation menu item",
    },
    {
        id: FLAGS.CHAT_BOT,
        name: PREFIX + FLAGS.CHAT_BOT,
        displayName: "Chat Bot",
        description: "See the customer support chat widget",
    },
    {
        id: FLAGS.GENERIC_QUALITY_RULE,
        name: PREFIX + FLAGS.GENERIC_QUALITY_RULE,
        displayName: "Generic Quality Rule in Campaign Editor",
        description:
            "View and edit the Custom Quality Rule in Advanced in the Rules tab of Campaign Editor",
    },
    {
        id: FLAGS.SHOW_ALL_VARIABLES,
        name: PREFIX + FLAGS.SHOW_ALL_VARIABLES,
        displayName: "Hidden Variables in Campaign Editor",
        description:
            "Show all variables in Campaign Editor, even if they’re not visible to clients",
    },
    {
        id: FLAGS.REWARD_SLOTS_DETAILS,
        name: PREFIX + FLAGS.REWARD_SLOTS_DETAILS,
        displayName: "Reward Slots in Campaign Editor",
        description:
            "See info about matching reward slots (“These rules apply to all rewards received across the following campaigns”) in the Rules tab of Campaign Editor when editing a legacy reward/reward rules",
    },
    {
        id: FLAGS.HIDE_PII,
        name: PREFIX + FLAGS.HIDE_PII,
        displayName: "Hide Personally Identifiable Information",
        description:
            "Hide customer profile pictures from Support and the Share Messages report from the account-level dashboard",
    },
    {
        id: FLAGS.HIDE_COUPON_CODE,
        name: PREFIX + FLAGS.HIDE_COUPON_CODE,
        displayName: "Disable Coupon Codes in Support",
        description: `Disable coupon code from being shown as a top-level parameter in View Event pages, and hide the "Upgrade Your Tag" link to Dev Docs if coupon code is not present`,
    },
    {
        id: FLAGS.HIDE_CART_VALUE,
        name: PREFIX + FLAGS.HIDE_CART_VALUE,
        displayName: "Disable Cart Value in Support",
        description: `Disable cart value from being shown as a top-level parameter in View Event pages, and hide the "Upgrade Your Tag" link to Dev Docs if cart value is not present`,
    },
    {
        id: FLAGS.FIXUPS_PAGE,
        name: PREFIX + FLAGS.FIXUPS_PAGE,
        displayName: "Fixups Page in Tech Center",
        description: "Access Fixup Logs from the Tech Center",
    },
    {
        id: FLAGS.ADVOCATE_MARKETING_PACKAGE,
        name: PREFIX + FLAGS.ADVOCATE_MARKETING_PACKAGE,
        displayName: "Advocate Marketing Package",
        description:
            "Unlock the following program types in the Program Picker: Influencer, FAF, and Nomination.",
    },
    {
        id: FLAGS.DROP_A_HINT_PROGRAM,
        name: PREFIX + FLAGS.DROP_A_HINT_PROGRAM,
        displayName: "Drop A Hint",
        description: "Unlock Drop A Hint in the Program Picker",
    },
    {
        id: FLAGS.NOMINATION,
        name: PREFIX + FLAGS.NOMINATION,
        displayName: "Nomination Program",
        description: "Unlock Nomination in the Program Picker",
    },
    {
        id: FLAGS.SWEEPSTAKES,
        name: PREFIX + FLAGS.SWEEPSTAKES,
        displayName: "Sweepstakes Program",
        description: "Unlock Sweepstakes in the Program Picker",
    },
    {
        id: FLAGS.INFLUENCER_PROGRAM,
        name: PREFIX + FLAGS.INFLUENCER_PROGRAM,
        displayName: "Influencer Program",
        description: "Unlock Influencer in the Program Picker",
    },
    {
        id: FLAGS.FRIENDS_AND_FAMILY,
        name: PREFIX + FLAGS.FRIENDS_AND_FAMILY,
        displayName: "Friends & Family Program",
        description: "Unlock Friends and Family in the Program Picker",
    },
    {
        id: FLAGS.WELCOME_OFFER,
        name: PREFIX + FLAGS.WELCOME_OFFER,
        displayName: "Welcome Offer Program",
        description: "Unlock Welcome Offer in the Program Picker",
    },
    {
        id: FLAGS.OFFER,
        name: PREFIX + FLAGS.OFFER,
        displayName: "Offer Program",
        description: "Unlock Offer in the Program Picker",
    },
    {
        id: FLAGS.PAYPAL_REWARD_SUPPLIER,
        name: PREFIX + FLAGS.PAYPAL_REWARD_SUPPLIER,
        displayName: "Paypal Reward Supplier",
        description: "Use Paypal as a reward supplier",
    },
    {
        id: FLAGS.ENFORCE_SHOPIFY_RESTRICTIONS,
        name: PREFIX + FLAGS.ENFORCE_SHOPIFY_RESTRICTIONS,
        displayName: "Enforce Shopify Restrictions",
        description:
            "Turning on the Shopify integration will exclude all other reward supplier options and hide programs that are not available",
    },
    {
        id: FLAGS.BETA_COMPONENT_LIBRARY,
        name: PREFIX + FLAGS.BETA_COMPONENT_LIBRARY,
        displayName: "Beta Component Library",
        description: "Access to Beta Components Library",
    },
]);

const service = {
    FLAGS,

    isOn(featureFlagId) {
        const flag = flags.findWhere({
            id: featureFlagId,
        });

        if (!flag) {
            return false;
        }

        return flag.isOn();
    },

    loadFlags() {
        const deferred = $.Deferred();
        flags.load().done(() => {
            deferred.resolve(flags);
        });
        return deferred;
    },
};

export default service;
