var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineStore } from "pinia";
import CampaignSummaryEndpoints from "src/shared/api/campaign/CampaignSummaryEndpoints";
import _ from "underscore";
import { computed, reactive } from "vue";
import { campaignArchiveEvent, campaignChangeEvent, } from "../event-bus/campaign";
import { campaignComponentChangeEvent } from "../event-bus/campaign-component";
import { campaignModuleEventBus, EVENT } from "../modules/campaign/event-bus";
import CampaignSummary from "./model/CampaignSummary";
export const useCampaignSummaryStore = defineStore("campaignSummaryStore", () => {
    const state = reactive({
        campaignSummaries: {},
    });
    const setCampaignSummaries = (campaignSummaryResponses) => {
        return _.map(campaignSummaryResponses, (response) => {
            const campaignSummary = new CampaignSummary(response);
            state.campaignSummaries[campaignSummary.campaign_id] =
                campaignSummary;
            return campaignSummary;
        });
    };
    const fetchCampaignSummaries = (payload) => __awaiter(void 0, void 0, void 0, function* () {
        return setCampaignSummaries(yield CampaignSummaryEndpoints.getCampaignSummaries(payload));
    });
    const fetchCampaignSummary = (campaignId) => __awaiter(void 0, void 0, void 0, function* () {
        setCampaignSummaries([
            yield CampaignSummaryEndpoints.getCampaignSummary(campaignId),
        ]);
    });
    const allCampaignSummaries = computed(() => {
        return _.values(state.campaignSummaries);
    });
    const getCampaignSummaries = (includeArchived) => {
        if (includeArchived) {
            return allCampaignSummaries.value;
        }
        return _.reject(allCampaignSummaries.value, (campaign) => campaign.isArchived());
    };
    const getCampaignSummary = (campaignId) => {
        return state.campaignSummaries[campaignId];
    };
    const getTags = (includeArchived = false) => {
        const campaignSummaries = getCampaignSummaries(includeArchived);
        return _.chain(campaignSummaries)
            .pluck("tags")
            .flatten()
            .uniq()
            .value();
    };
    campaignModuleEventBus.subscribe(EVENT.CAMPAIGN_INVALIDATED, (payload) => {
        fetchCampaignSummary(payload.campaignId);
    });
    campaignModuleEventBus.subscribe(EVENT.CAMPAIGNS_INVALIDATED, () => {
        fetchCampaignSummaries({
            include_archived: true,
        });
    });
    campaignComponentChangeEvent.on((event) => {
        fetchCampaignSummary(event.campaignId);
    });
    campaignChangeEvent.on((event) => {
        fetchCampaignSummary(event.campaignId);
    });
    campaignArchiveEvent.on((event) => {
        delete state.campaignSummaries[event.campaignId];
    });
    return {
        state,
        fetchCampaignSummary,
        fetchCampaignSummaries,
        getCampaignSummary,
        getCampaignSummaries,
        getTags,
    };
});
