import _ from "underscore";
import { createApp, h } from "vue";
import Uri from "jsuri";
import { ElNotification } from "element-plus";
import NotificationApp from "./NotificationApp.vue";
const ON_START_NOTIFICATION_MESSAGE_PARAMETER = "on-start-notification-message";
export const notify = (notification) => {
    return ElNotification(Object.assign(Object.assign({}, notification), { position: "bottom-left" }));
};
const renderOnStartNotification = () => {
    const uri = new Uri(String(window.location));
    const appNotificationMessage = uri.getQueryParamValue(ON_START_NOTIFICATION_MESSAGE_PARAMETER);
    if (appNotificationMessage) {
        const withoutNotification = new Uri(String(window.location))
            .deleteQueryParam(ON_START_NOTIFICATION_MESSAGE_PARAMETER)
            .toString();
        window.history.replaceState({}, "", withoutNotification);
        const message = _.escape(decodeURIComponent(appNotificationMessage));
        notify({
            message,
        });
    }
};
const normalize = (message) => {
    if (typeof message === "string") {
        return h("span", { innerHTML: message });
    }
    return message;
};
class NotificationService {
    constructor() {
        this.isStarted = false;
    }
    start() {
        if (this.isStarted) {
            return;
        }
        const notificationContainer = document.createElement("div");
        document.body.appendChild(notificationContainer);
        createApp(NotificationApp).mount(notificationContainer);
        renderOnStartNotification();
        this.isStarted = true;
    }
    success(message, duration) {
        notify({
            title: "Success",
            message: normalize(message),
            duration,
            type: "success",
        });
    }
    error(message) {
        notify({
            title: "Error",
            message: normalize(message),
            type: "error",
        });
    }
}
export default new NotificationService();
