import { computed } from "vue";
import { useGlobalCampaignSummary } from "../../campaign-summary/composables";
import { useBuiltComponents } from "./useBuiltComponents";
export const useGlobalCampaignRootBuiltComponent = () => {
    const { primer, globalCampaign } = useGlobalCampaignSummary();
    const { builtComponents } = useBuiltComponents();
    const globalCampaignRootBuiltComponent = computed(() => {
        if (!globalCampaign.value) {
            return undefined;
        }
        return builtComponents.value.find((component) => {
            var _a;
            return (component.campaign_id === ((_a = globalCampaign.value) === null || _a === void 0 ? void 0 : _a.campaign_id) &&
                component.name === "root");
        });
    });
    return {
        globalCampaignRootBuiltComponent,
    };
};
