import _ from "underscore";
import { CampaignComponentVariableCommonImpl } from "./CampaignComponentVariableCommonImpl";
class CampaignComponentVariable {
    constructor(variableResponse, context) {
        this.name = variableResponse.name;
        this.display_name = variableResponse.display_name;
        this.description = variableResponse.description;
        this.source = variableResponse.source;
        this.type = variableResponse.type;
        this.tags = variableResponse.tags;
        this.priority = variableResponse.priority;
        this.values = variableResponse.values;
        this.context = context;
        this.campaignComponentVariableCommonImpl =
            new CampaignComponentVariableCommonImpl(variableResponse);
    }
    getDimensions() {
        return _.keys(this.values);
    }
    getDisplayName() {
        return this.campaignComponentVariableCommonImpl.getDisplayName();
    }
    isUIDisplayOnly() {
        return this.campaignComponentVariableCommonImpl.isUIDisplayOnly();
    }
    getImportance() {
        return this.campaignComponentVariableCommonImpl.getImportance();
    }
    isTranslatable() {
        return this.campaignComponentVariableCommonImpl.isTranslatable();
    }
    getPrimaryCategory() {
        return this.campaignComponentVariableCommonImpl.getPrimaryCategory();
    }
    getSecondaryCategory() {
        return this.campaignComponentVariableCommonImpl.getSecondaryCategory();
    }
    isHidden() {
        return this.campaignComponentVariableCommonImpl.isHidden();
    }
    getValue(variant) {
        return this.campaignComponentVariableCommonImpl.getValue(variant);
    }
    hasVariant(variant) {
        return this.campaignComponentVariableCommonImpl.hasVariant(variant);
    }
    isAdvanced() {
        return this.campaignComponentVariableCommonImpl.isAdvanced();
    }
}
CampaignComponentVariable.DEFAULT_DIMENSION = "default";
const variableTypeOptionDefinitions = {
    STRING: {
        displayName: "String",
        value: "STRING",
    },
    BOOLEAN: {
        displayName: "Boolean",
        value: "BOOLEAN",
    },
    INTEGER: {
        displayName: "Integer",
        value: "INTEGER",
    },
    STRING_LIST: {
        displayName: "String List",
        value: "STRING_LIST",
    },
    INTEGER_LIST: {
        displayName: "Integer List",
        value: "INTEGER_LIST",
    },
    JSON: {
        displayName: "Json",
        value: "JSON",
    },
    COLOR: {
        displayName: "Color",
        value: "COLOR",
    },
    IMAGE: {
        displayName: "Image",
        value: "IMAGE",
    },
    URL: {
        displayName: "Url",
        value: "URL",
    },
    CLIENT_KEY_FLOW: {
        displayName: "Client Key Flow",
        value: "CLIENT_KEY_FLOW",
    },
    CLIENT_KEY: {
        displayName: "Client Key",
        value: "CLIENT_KEY",
    },
    EXTOLE_CLIENT_KEY: {
        displayName: "Extole Client Key",
        value: "EXTOLE_CLIENT_KEY",
    },
    REWARD_SUPPLIER_ID: {
        displayName: "Reward Supplier Id",
        value: "REWARD_SUPPLIER_ID",
    },
    AUDIENCE_ID: {
        displayName: "Audience Id",
        value: "AUDIENCE_ID",
    },
    ENUM: {
        displayName: "Enum",
        value: "ENUM",
    },
};
export const variableTypeOptions = _.values(variableTypeOptionDefinitions);
const variableSourceOptionDefinitions = {
    LOCAL: {
        displayName: "Local",
        value: "LOCAL",
    },
    INHERITED: {
        displayName: "Inherited",
        value: "INHERITED",
    },
};
export const variableSourceOptions = _.values(variableSourceOptionDefinitions);
export default CampaignComponentVariable;
