import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import template from "src/shared/notification/notification-ellipsis-template.html?raw";

const NotificationEllipsisView = Backbone.View.extend({
    initialize(options) {
        this.$notifications = options.$notifications;
    },

    render() {
        if (this.$notifications.length === 0) {
            return;
        }

        this.$notifications.each(function () {
            $(this).html(template);
        });
    },
});

NotificationEllipsisView.renderAll = function ($el) {
    if (!($el instanceof $)) {
        $el = $($el);
    }
    return new NotificationEllipsisView({
        $notifications: $el.find(".js-notification-ellipsis"),
    }).render();
};

NotificationEllipsisView.create = function ($el) {
    if (!($el instanceof $)) {
        $el = $($el);
    }
    return new NotificationEllipsisView({
        $notifications: $el,
    }).render();
};

export default NotificationEllipsisView;
