import _ from "underscore";
import Session from "Session";
import moment from "moment";
import momentTimezone from "moment-timezone";

const TIME_RANGE_SEPARATOR = /\]\/|~/;
const TIME_RANGE_WITHOUT_TIMEZONE_SEPARATOR = /\/|~/;
const TIME_OFFSET_REGEX = /([+|-]\d\d:\d\d)|Z|[[|/]/;
const STRING_TIME_RANGE_SEPARATOR = " - ";
const QUERY_TIME_RANGE_SEPARATOR = "/";
const TIMEZONE_REGEX = /\[(.*?)(]|$)/;
const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;
const MAXIMUM_YEAR_LENGTH = 4;
export const FORMATS = {
    DISPLAY_DATE: {
        format: "M/D/YY",
        range_separator: STRING_TIME_RANGE_SEPARATOR,
    },
    DISPLAY_DATE_TIME: {
        format: "M/D/YY h:mm a",
        range_separator: STRING_TIME_RANGE_SEPARATOR,
    },
    FULL_DISPLAY_DATE_TIME: {
        format: "MMM D YYYY h:mm a",
        range_separator: STRING_TIME_RANGE_SEPARATOR,
    },
    QUERY_DATE: {
        format: "YYYY-MM-DD",
        range_separator: QUERY_TIME_RANGE_SEPARATOR,
    },
    QUERY_DATE_WITH_ZERO_TIME: {
        format: "YYYY-MM-DDT00:00:00",
        range_separator: QUERY_TIME_RANGE_SEPARATOR,
    },
    QUERY_DATE_TIME: {
        format: "YYYY-MM-DDTHH:mm:ss",
        range_separator: QUERY_TIME_RANGE_SEPARATOR,
    },
    ISO_DATE_TIME_FORMAT: {
        format: "YYYY-MM-DDTHH:mm:ss.SSS",
    },
    TIME: {
        format: "h:mm:ss a",
    },
    MONTH_DATE: {
        format: "MMM",
    },
    WEEK_DATE: {
        format: "w",
    },
    DAY_DATE: {
        format: "ddd",
    },
};

function initializeMoment() {
    moment.locale("en", {
        ordinal(number) {
            const b = number % 10;
            let output;
            if (~~((number % 100) / 10) === 1) {
                output = "th";
            } else {
                switch (b) {
                    case 1:
                        output = "st";
                        break;

                    case 2:
                        output = "nd";
                        break;

                    case 3:
                        output = "rd";
                        break;

                    default:
                        output = "th";
                        break;
                }
            }
            return number + output;
        },
    });
}

function getTimezoneName(timestamp) {
    const clientTimezoneName = Session.getInstance().getClient().getTimezone();
    if (_.isString(timestamp)) {
        let timezoneMatch = timestamp.match(TIMEZONE_REGEX);
        if (!timezoneMatch || !timezoneMatch[1]) {
            if (clientTimezoneName) {
                return clientTimezoneName;
            }
            timezoneMatch = timestamp.match(TIME_OFFSET_REGEX);
            if (timezoneMatch && timezoneMatch[1]) {
                return getTimezoneNameForOffset(timezoneMatch[1]);
            }
        }
        return (timezoneMatch && timezoneMatch[1]) || clientTimezoneName;
    }

    return clientTimezoneName;
}

function getTimezoneNameForOffset(timezoneOffset) {
    return _.find(moment.tz.names(), (timezoneName) => {
        return timezoneOffset === moment.tz(timezoneName).format("Z");
    });
}

function cleanupTimestamp(timestamp) {
    if (_.isString(timestamp)) {
        const match = timestamp.match(TIME_OFFSET_REGEX);
        if (match) {
            return timestamp.substring(0, match.index);
        }
    }
    return timestamp;
}

function initializeMomentTimestamp(timestamp) {
    return timestamp && _.isFunction(timestamp.isValid)
        ? timestamp
        : moment(timestamp || undefined);
}

function toDate(timestamp) {
    let dateTime;
    if (_.isString(timestamp)) {
        const timezoneName = getTimezoneName(timestamp);
        dateTime = moment(cleanupTimestamp(timestamp)).tz(timezoneName);
    } else {
        dateTime = initializeMomentTimestamp(timestamp);
    }
    return new Date(dateTime.toISOString());
}

function getFormattedTimezone(timezoneName) {
    return ` ${momentTimezone().tz(timezoneName).format("z")}`;
}

function DateTime(timestamps, formatConfiguration, options) {
    initializeMoment();

    function formatTimestamp(timestamp) {
        let dateTime = initializeMomentTimestamp(cleanupTimestamp(timestamp));
        const timezoneName = getTimezoneName(timestamp);
        const postfix = options.withTimezoneName
            ? getFormattedTimezone(timezoneName)
            : "";

        if (!timestamp || options.withConversion) {
            dateTime = dateTime.tz(timezoneName);
        }
        return dateTime.format(formatConfiguration.format) + postfix;
    }

    this.format = function () {
        if (_.isNumber(timestamps)) {
            timestamps = [moment.utc(timestamps)];
        } else if (_.isString(timestamps)) {
            const separator =
                timestamps.indexOf("[") > -1
                    ? TIME_RANGE_SEPARATOR
                    : TIME_RANGE_WITHOUT_TIMEZONE_SEPARATOR;
            const separatorIndex =
                separator.test(timestamps) && timestamps.match(separator).index;
            timestamps =
                separatorIndex >= 0 && separatorIndex <= MAXIMUM_YEAR_LENGTH
                    ? [timestamps.replace(/\//g, "-")]
                    : timestamps.split(separator);
        } else if (
            !timestamps ||
            (_.isObject(timestamps) && !_.isArray(timestamps))
        ) {
            timestamps = [timestamps];
        }
        return _.map(timestamps, (currentTimestamp) =>
            formatTimestamp(currentTimestamp)
        ).join(formatConfiguration.range_separator);
    };
}

function DateTimeBuilder(timestamps) {
    let _withTimezoneName = false;
    let _withConversion = false;
    let _formatConfiguration;

    this.withFormatConfiguration = function (formatConfiguration) {
        _formatConfiguration = formatConfiguration;
        return this;
    };

    this.withConversion = function (withConversion) {
        _withConversion = withConversion;
        return this;
    };

    this.withTimezoneName = function (withTimezoneName) {
        _withTimezoneName = _.isUndefined(withTimezoneName)
            ? true
            : withTimezoneName;
        return this;
    };

    this.build = function () {
        return new DateTime(timestamps, _formatConfiguration, {
            withTimezoneName: _withTimezoneName,
            withConversion: _withConversion,
        });
    };
}

export default {
    formatToDateTime(timestamps, withConversion, withTimezoneName) {
        return new DateTimeBuilder(timestamps)
            .withFormatConfiguration(FORMATS.DISPLAY_DATE_TIME)
            .withConversion(withConversion)
            .withTimezoneName(withTimezoneName)
            .build()
            .format();
    },

    formatToFullDateTime(timestamps, withConversion, withTimezoneName) {
        return new DateTimeBuilder(timestamps)
            .withFormatConfiguration(FORMATS.FULL_DISPLAY_DATE_TIME)
            .withConversion(withConversion)
            .withTimezoneName(withTimezoneName)
            .build()
            .format();
    },

    formatToQueryDateTime(timestamps, withConversion) {
        return new DateTimeBuilder(timestamps)
            .withFormatConfiguration(FORMATS.QUERY_DATE_TIME)
            .withConversion(withConversion)
            .build()
            .format();
    },

    formatToDate(timestamps, withConversion, withTimezoneName) {
        return new DateTimeBuilder(timestamps)
            .withFormatConfiguration(FORMATS.DISPLAY_DATE)
            .withConversion(withConversion)
            .withTimezoneName(withTimezoneName)
            .build()
            .format();
    },

    formatToTime(timestamps, withConversion, withTimezoneName) {
        return new DateTimeBuilder(timestamps)
            .withFormatConfiguration(FORMATS.TIME)
            .withConversion(withConversion)
            .withTimezoneName(withTimezoneName)
            .build()
            .format();
    },

    formatToQueryDate(timestamps, withConversion) {
        return new DateTimeBuilder(timestamps)
            .withFormatConfiguration(FORMATS.QUERY_DATE_WITH_ZERO_TIME)
            .withConversion(withConversion)
            .build()
            .format();
    },

    formatToQueryDateWithoutTime(timestamps, withConversion) {
        return new DateTimeBuilder(timestamps)
            .withFormatConfiguration(FORMATS.QUERY_DATE)
            .withConversion(withConversion)
            .build()
            .format();
    },

    formatToTimeDifference(dateEnd, dateStart) {
        const formattedDateEnd = this.formatToQueryDateTime(dateEnd);
        const formattedDateStart = this.formatToQueryDateTime(dateStart);
        let seconds = moment(formattedDateEnd).diff(
            formattedDateStart,
            "seconds"
        );
        let minutes = parseInt(seconds / SECONDS_IN_MINUTE);
        let hours = 0;
        seconds -= minutes * SECONDS_IN_MINUTE;

        if (minutes >= MINUTES_IN_HOUR) {
            hours = parseInt(minutes / MINUTES_IN_HOUR);
            minutes -= hours * MINUTES_IN_HOUR;
        }

        return `${
            (hours ? `${hours}:` : "") + (minutes < 10 ? "0" : "") + minutes
        }:${seconds < 10 ? "0" : ""}${seconds}`;
    },

    formatToMonth(timestamps, withConversion) {
        return new DateTimeBuilder(timestamps)
            .withFormatConfiguration(FORMATS.MONTH_DATE)
            .withConversion(withConversion)
            .build()
            .format();
    },

    formatToWeek(timestamps, withConversion) {
        return new DateTimeBuilder(timestamps)
            .withFormatConfiguration(FORMATS.WEEK_DATE)
            .withConversion(withConversion)
            .build()
            .format();
    },

    formatToDay(timestamps, withConversion) {
        return new DateTimeBuilder(timestamps)
            .withFormatConfiguration(FORMATS.DAY_DATE)
            .withConversion(withConversion)
            .build()
            .format();
    },

    rangeToDates(timeRange) {
        return (
            timeRange &&
            _.map(timeRange.split(TIME_RANGE_SEPARATOR), (timestamp) =>
                toDate(timestamp)
            )
        );
    },

    getIsoDateTimeFormat(timestamp) {
        return `${moment
            .utc(timestamp)
            .format(FORMATS.ISO_DATE_TIME_FORMAT.format)}Z`;
    },

    getQueryDateTimeFormat() {
        return FORMATS.QUERY_DATE_WITH_ZERO_TIME.format;
    },

    getQueryDateFormat() {
        return FORMATS.QUERY_DATE.format;
    },

    toDate(timestamp, withConversion) {
        if (withConversion) {
            timestamp = this.formatToQueryDateTime(timestamp, true);
        }
        return toDate(timestamp);
    },

    toCalendarTerm(timestamp) {
        const clientTimezoneName = Session.getInstance()
            .getClient()
            .getTimezone();
        const dateWithTimezone = moment.utc(timestamp).tz(clientTimezoneName);
        return `${dateWithTimezone.calendar()} ${getFormattedTimezone(
            clientTimezoneName
        )}`;
    },

    isDateValid(timestamp) {
        return timestamp && moment(cleanupTimestamp(timestamp)).isValid();
    },

    getDifferenceFromNow(timestamp, timeunits) {
        const now = moment();
        const daysLeft = moment(timestamp).diff(now, timeunits || "days", true);
        return daysLeft;
    },

    isDateInFuture(timestamp) {
        const timeLeft = this.getDifferenceFromNow(timestamp, "seconds");
        return timeLeft > 0;
    },

    isDateInPast(timestamp) {
        const timeLeft = this.getDifferenceFromNow(timestamp, "seconds");
        return timeLeft < 0;
    },

    fromUnix(timestamp) {
        return toDate(moment.unix(timestamp), true);
    },

    valueOf(timestamp, withConversion) {
        return moment
            .utc(this.formatToQueryDateTime(timestamp, withConversion))
            .valueOf();
    },

    isEquals(date1, date2) {
        return moment(date1).isSame(moment(date2));
    },

    combineDateTime(date, time) {
        const convertedDate = moment.tz(
            date,
            FORMATS.QUERY_DATE.format,
            Session.getInstance().getClient().getTimezone()
        );
        const convertedTime = moment(
            time,
            FORMATS.TIME.format,
            Session.getInstance().getClient().getTimezone()
        );
        convertedDate.hour(convertedTime.hour());
        convertedDate.minute(convertedTime.minute());
        return convertedDate.format();
    },

    getFullMonthName(date) {
        const convertedDate = moment(date.period_start, "YYYY-MM-DD").format(
            "MMMM YYYY"
        );
        return convertedDate;
    },
};
