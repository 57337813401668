import ModificationAudienceOperation from "./operation/model/ModificationAudienceOperation";
export class AudienceCreateMutable {
    constructor() {
        this.component_ids = [];
    }
}
export class AudienceUpdateMutable {
    constructor(audience) {
        this.name = audience.name;
        this.enabled = audience.enabled;
        this.tags = audience.tags;
        this.component_ids = audience.component_ids;
    }
}
export class AudienceOperationCreateMutable {
    constructor() {
        this.type = "ADD";
        this.tags = [];
    }
}
export class AudienceOperationDataSourceCreateMutable {
}
export class FileAssetAudienceOperationDataSourceCreateMutable extends AudienceOperationDataSourceCreateMutable {
    constructor() {
        super();
        this.type = "FILE_ASSET";
        this.type = "FILE_ASSET";
        this.event_columns = ["email"];
    }
}
export class ReportAudienceOperationDataSourceCreateMutable extends AudienceOperationDataSourceCreateMutable {
    constructor() {
        super();
        this.type = "REPORT";
        this.type = "REPORT";
        this.event_columns = ["email"];
    }
}
export class PersonListAudienceOperationDataSourceCreateMutable extends AudienceOperationDataSourceCreateMutable {
    constructor() {
        super();
        this.type = "PERSON_LIST";
        this.type = "PERSON_LIST";
        this.audience_members = [];
    }
}
export class AudienceMemberCreateMutable {
    constructor(email, data) {
        this.email = email;
        this.data = data;
    }
}
export class ActionAudienceOperationDataSourceCreateMutable extends AudienceOperationDataSourceCreateMutable {
    constructor() {
        super();
        this.type = "ACTION";
        this.type = "ACTION";
        this.event_columns = [];
    }
}
function createDataSourceCreateRequest(type) {
    switch (type) {
        case "FILE_ASSET":
            return new FileAssetAudienceOperationDataSourceCreateMutable();
        case "PERSON_LIST":
            return new PersonListAudienceOperationDataSourceCreateMutable();
        case "REPORT":
            return new ReportAudienceOperationDataSourceCreateMutable();
        default:
            throw new Error("Unknown data source type");
    }
}
export class ModificationAudienceOperationCreateMutable extends AudienceOperationCreateMutable {
    constructor(type, operation) {
        super();
        if (operation &&
            ModificationAudienceOperation.isModificationAudienceOperation(operation)) {
            this.type = operation.type;
            this.tags = operation.tags;
            this.operation_type = operation.data_source.type;
            this.data_source = operation.data_source;
            return;
        }
        this.type = "ADD";
        this.operation_type = type;
        this.data_source = createDataSourceCreateRequest(this.operation_type);
    }
    isFileAssetDataSource() {
        return this.data_source.type === "FILE_ASSET";
    }
    isReportDataSource() {
        return this.data_source.type === "REPORT";
    }
    isPersonListDataSource() {
        return this.data_source.type === "PERSON_LIST";
    }
}
export class ActionAudienceOperationCreateMutable extends AudienceOperationCreateMutable {
    constructor() {
        super();
        this.type = "ACTION";
        this.data_source = new ActionAudienceOperationDataSourceCreateMutable();
    }
}
