var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { reactive, watchEffect } from "vue";
import { useAudienceStore } from "..";
import { audienceOperationChangeEvent } from "../operation/event-bus";
export const useAudienceStats = (audienceId) => {
    const resultSet = reactive({
        results: undefined,
        isLoading: true,
    });
    const audienceStore = useAudienceStore();
    const updateResults = () => __awaiter(void 0, void 0, void 0, function* () {
        yield audienceStore.fetchStats(audienceId);
        resultSet.results = audienceStore.getStats(audienceId);
        resultSet.isLoading = false;
    });
    watchEffect(updateResults);
    audienceOperationChangeEvent.on(({ changedAudienceId }) => {
        if (changedAudienceId === audienceId) {
            updateResults();
        }
    });
    return resultSet;
};
