import { CampaignStep } from "./CampaignStep";
export const isCampaignJourneyEntry = (step) => {
    return step.type === "JOURNEY_ENTRY";
};
export class CampaignJourneyEntry extends CampaignStep {
    constructor(response, context) {
        super(response, context);
        this.journey_name = response.journey_name;
        this.priority = response.priority;
        this.triggers = response.triggers;
    }
}
