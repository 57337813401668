var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { computed, toValue } from "vue";
import { useBuiltComponentStore } from "..";
import { useAsyncPrimer } from "../../common/async-primer";
import { excludeHavingAllTags, excludeHavingAnyTags, havingAllTags, havingAnyTags, } from "src/shared/Tags";
const filterBuiltComponentsByListRequest = (components, data) => {
    return components.filter((component) => {
        return (havingAllTags(component, data.havingAllTags) &&
            havingAnyTags(component, data.havingAnyTags) &&
            excludeHavingAllTags(component, data.excludeHavingAllTags) &&
            excludeHavingAnyTags(component, data.excludeHavingAnyTags) &&
            (!data.owner || component.owner === data.owner));
    });
};
export const useBuiltComponents = (data) => {
    const builtComponentStore = useBuiltComponentStore();
    const primer = useAsyncPrimer(() => __awaiter(void 0, void 0, void 0, function* () {
        yield builtComponentStore.fetchBuiltComponents();
    }), "useBuiltComponents");
    const builtComponents = computed(() => {
        if (!data) {
            return builtComponentStore.getBuiltComponents();
        }
        else {
            return filterBuiltComponentsByListRequest(builtComponentStore.getBuiltComponents(), toValue(data));
        }
    });
    return {
        builtComponents,
        primer,
    };
};
