import _ from "underscore";
import dateTimeService from "src/shared/date-time-service";
import { createCampaignSteps } from "./factory/campaignStepFactory";
export const HIDDEN_TAG_NAME = "hidden";
const OPEN_DATE_NAME = "Forever";
export const CAMPAIGN_TYPES = [
    { displayName: "Marketing", value: "MARKETING" },
    { displayName: "Integration", value: "INTEGRATION" },
    { displayName: "Configuration", value: "CONFIGURATION" },
    { displayName: "Extension", value: "EXTENSION" },
];
class Campaign {
    constructor(campaignResponse) {
        Object.assign(this, campaignResponse);
        this.campaign_id = campaignResponse.campaign_id;
        this.name = campaignResponse.name;
        this.tags = campaignResponse.tags;
        this.steps = createCampaignSteps(campaignResponse.steps, campaignResponse.campaign_id);
    }
    getStep(stepId) {
        return this.steps.find((step) => step.id === stepId);
    }
    clone() {
        return new Campaign(JSON.parse(JSON.stringify(this)));
    }
    hasDraft() {
        const publishedDate = new Date(this.last_published_date);
        const updatedDate = new Date(this.updated_date);
        return updatedDate > publishedDate;
    }
    isLive() {
        return this.state === "LIVE";
    }
    isPaused() {
        return this.state === "PAUSED";
    }
    isArchived() {
        return this.state === "ARCHIVED";
    }
    isEnded() {
        return this.state === "ENDED";
    }
    isHidden() {
        var _a;
        return (_a = this.tags) === null || _a === void 0 ? void 0 : _a.includes(HIDDEN_TAG_NAME);
    }
    isScheduled() {
        return (dateTimeService.isDateInFuture(this.start_date) ||
            (this.start_date !== null &&
                dateTimeService.isDateInFuture(this.stop_date)) ||
            (this.pause_date !== null &&
                dateTimeService.isDateInFuture(this.pause_date)));
    }
    isOpenEndDated() {
        return this.isScheduled() && this.stop_date === null;
    }
    getStartDatePretty() {
        return this.start_date
            ? dateTimeService.formatToFullDateTime(this.start_date)
            : null;
    }
    getEndDatePretty() {
        return this.stop_date
            ? dateTimeService.formatToFullDateTime(this.stop_date)
            : OPEN_DATE_NAME;
    }
    getLastUpdatedPretty() {
        return dateTimeService.formatToDateTime(this.updated_date);
    }
    getLastPublishedPretty() {
        return this.last_published_date
            ? dateTimeService.formatToDateTime(this.last_published_date)
            : null;
    }
    getLastUpdatedDescription() {
        if (this.is_published) {
            return `Published: ${this.getLastPublishedPretty()}`;
        }
        return `Draft Saved: ${this.getLastUpdatedPretty()}`;
    }
    isLocked() {
        return !_.isEmpty(this.campaign_locks);
    }
}
export default Campaign;
