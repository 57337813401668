import StringComponentVariableValueInput from "./edit-mode/StringComponentVariableValueInput.vue";
import StringListComponentVariableValueInput from "./edit-mode/StringListComponentVariableValueInput.vue";
import ScheduleListComponentVariableValueInput from "./schedule/ScheduleListComponentVariableValueInput.vue";
import BooleanComponentVariableValueInput from "./edit-mode/BooleanComponentVariableValueInput.vue";
import ColorComponentVariableValueInput from "./edit-mode/ColorComponentVariableValueInput.vue";
import IntegerComponentVariableValueInput from "./edit-mode/IntegerComponentVariableValueInput.vue";
import EnumComponentVariableValueInput from "./edit-mode/EnumComponentVariableValueInput.vue";
import StringComponentVariableValueDisplay from "./display-mode/StringComponentVariableValueDisplay.vue";
import BooleanComponentVariableValueDisplay from "./display-mode/BooleanComponentVariableValueDisplay.vue";
import IntegerComponentVariableValueDisplay from "./display-mode/IntegerComponentVariableValueDisplay.vue";
import ColorComponentVariableValueDisplay from "./display-mode/ColorComponentVariableValueDisplay.vue";
import ImageComponentVariableValueDisplay from "./display-mode/ImageComponentVariableValueDisplay.vue";
import StringListComponentVariableValueDisplay from "./display-mode/StringListComponentVariableValueDisplay.vue";
import ScheduleListComponentVariableValueDisplay from "./schedule/ScheduleListComponentVariableValueDisplay.vue";
import CampaignComponentVariableInputLayout from "./CampaignComponentVariableInputLayout.vue";
import CampaignComponentVariableInputLayoutInline from "./CampaignComponentVariableInputLayoutInline.vue";
import ClientKeyVariableValuePickerInput from "src/shared/v-components/campaign-component-variable/input/ClientKeyVariableValuePickerInput.vue";
import { computed } from "vue";
import { normalizePreferredVariant, calculateWorkingVariant, } from "src/shared/store/campaign-built/variants";
import RewardSupplierIdComponentVariableValueInput from "./edit-mode/RewardSupplierIdComponentVariableValueInput.vue";
import AudienceSelector from "./audience/AudienceSelector.vue";
export const useWorkingValue = (preferredDimension, values) => {
    const normalizedPreferredVariant = computed(() => {
        return normalizePreferredVariant(preferredDimension.value);
    });
    const workingVariant = computed(() => {
        return calculateWorkingVariant(normalizedPreferredVariant.value, values.value);
    });
    const workingValue = computed({
        get() {
            return values.value[workingVariant.value];
        },
        set(value) {
            const clone = Object.assign({}, values.value);
            clone[workingVariant.value] = value;
            values.value = clone;
        },
    });
    return {
        dimension: workingVariant,
        value: workingValue,
    };
};
export const getEditModeValueComponent = (type, tags) => {
    switch (type) {
        case "BOOLEAN":
            return BooleanComponentVariableValueInput;
        case "STRING":
            return StringComponentVariableValueInput;
        case "COLOR":
            return ColorComponentVariableValueInput;
        case "INTEGER":
            return IntegerComponentVariableValueInput;
        case "IMAGE":
            return StringComponentVariableValueInput;
        case "REWARD_SUPPLIER_ID":
            return RewardSupplierIdComponentVariableValueInput;
        case "STRING_LIST":
            if (tags === null || tags === void 0 ? void 0 : tags.some((tag) => /enum/.test(tag))) {
                return EnumComponentVariableValueInput;
            }
            return StringListComponentVariableValueInput;
        case "INTEGER_LIST":
            return ScheduleListComponentVariableValueInput;
        case "CLIENT_KEY":
            return ClientKeyVariableValuePickerInput;
        case "AUDIENCE_ID":
            return AudienceSelector;
        default:
            return StringComponentVariableValueInput;
    }
};
export const getDisplayModeValueComponent = (type, isEvalutable) => {
    if (isEvalutable) {
        return StringComponentVariableValueDisplay;
    }
    switch (type) {
        case "BOOLEAN":
            return BooleanComponentVariableValueDisplay;
        case "STRING":
            return StringComponentVariableValueDisplay;
        case "COLOR":
            return ColorComponentVariableValueDisplay;
        case "INTEGER":
            return IntegerComponentVariableValueDisplay;
        case "IMAGE":
            return ImageComponentVariableValueDisplay;
        case "STRING_LIST":
            return StringListComponentVariableValueDisplay;
        case "INTEGER_LIST":
            return ScheduleListComponentVariableValueDisplay;
        case "AUDIENCE_ID":
            return AudienceSelector;
        default:
            return StringComponentVariableValueDisplay;
    }
};
const inlineDisplayTypes = new Set().add("BOOLEAN");
export const getLayoutComponent = (type, isEvalutable) => {
    if (isEvalutable || !inlineDisplayTypes.has(type)) {
        return CampaignComponentVariableInputLayout;
    }
    else {
        return CampaignComponentVariableInputLayoutInline;
    }
};
