var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { computed, ref } from "vue";
import { useClientKeyStore } from ".";
import { useAsyncPrimer } from "../common/async-primer";
import { useFuzzySearch } from "../common/fuzzy-search";
import { isEmpty } from "underscore";
export const useClientKeys = () => {
    const clientKeyStore = useClientKeyStore();
    const primer = useAsyncPrimer(() => __awaiter(void 0, void 0, void 0, function* () {
        if (isEmpty(clientKeyStore.getBuiltClientKeys())) {
            yield clientKeyStore.fetchBuiltClientKeys();
        }
    }), "useClientKeys");
    const clientKeys = computed(() => clientKeyStore.getBuiltClientKeys());
    return {
        clientKeys,
        primer,
    };
};
export const useClientKey = (id) => {
    const clientKeyStore = useClientKeyStore();
    const { primer } = useClientKeys();
    const clientKey = computed(() => clientKeyStore.getBuiltClientKey(id));
    return {
        clientKey,
        primer,
    };
};
export const filterClientKeys = (clientKeys, parameters) => {
    const filtered = useFuzzySearch(parameters.search, clientKeys, [
        "id",
        "name",
        "type",
    ]);
    return filtered;
};
export const useWebhookClientKeys = () => {
    const search = ref("WEBHOOK");
    const { clientKeys, primer } = useClientKeys();
    const filteredClientKeys = computed(() => {
        return filterClientKeys(clientKeys, {
            search,
        }).value;
    });
    return {
        primer,
        filteredClientKeys,
    };
};
