import _ from "underscore";
import Uri from "jsuri";

const FILTER_PARAMETER_PREFIX = "filter_parameter:";

function getParameter(key) {
    return getParameters()[key];
}

function setParameter(key, value) {
    const uri = new Uri(window.location);
    uri.replaceQueryParam(key, value);
    window.history.replaceState({}, null, uri.toString());
}

function setParameters(parameters) {
    _.each(parameters, (value, key) => {
        setParameter(key, value);
    });
}

function toggleParameter(key, value) {
    if (value) {
        setParameter(key, value);
    } else {
        removeParameter(key);
    }
}

function removeParameter(key) {
    const uri = new Uri(window.location);
    uri.deleteQueryParam(key);
    window.history.replaceState({}, null, uri.toString());
}

function hasParameter(key) {
    return _.has(getParameters(), key);
}

function getParameters() {
    const uri = new Uri(window.location);
    const parameters = {};
    _.each(uri.queryPairs, (pair) => {
        parameters[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    });
    return parameters;
}

function getFilterParameters() {
    const parameters = getParameters();
    const filterParameters = {};
    _.each(parameters, (value, key) => {
        if (key.startsWith(FILTER_PARAMETER_PREFIX)) {
            const cleanedKey = key.replace(FILTER_PARAMETER_PREFIX, "");
            filterParameters[cleanedKey] = value;
        }
    });
    return filterParameters;
}

function updateFilterParameters(parameters) {
    const existingParameters = getFilterParameters();
    _.each(existingParameters, (value, key) => {
        const prefixedKey = FILTER_PARAMETER_PREFIX + key;
        if (!_.has(parameters, key)) {
            removeParameter(prefixedKey);
        }
    });

    _.each(parameters, (value, key) => {
        const prefixedKey = FILTER_PARAMETER_PREFIX + key;
        setParameter(prefixedKey, value);
    });
}

export default {
    getParameter,
    setParameter,
    setParameters,
    toggleParameter,
    removeParameter,
    hasParameter,
    getParameters,
    getFilterParameters,
    updateFilterParameters,
};
