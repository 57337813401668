import { CampaignControllerTrigger } from "./CampaignControllerTrigger";
export const isCampaignControllerTriggerEvent = (trigger) => {
    return trigger.trigger_type === "EVENT";
};
export class CampaignControllerTriggerEvent extends CampaignControllerTrigger {
    constructor(response, context) {
        super(response, context);
        this.event_names = response.event_names;
        this.event_type = response.event_type;
    }
}
